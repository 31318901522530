import React from "react"
import { Link } from "gatsby"
import styles from "./article-preview.module.css"
import PropTypes from "prop-types"

function ArticlePreview({ title, rawDate, prettyDate, pagePath }) {
  return (
    <article className={styles.container}>
      <time className={styles.date} dateTime={rawDate}>
        {prettyDate}
      </time>
      <h3 className={styles.title}>
        <Link to={pagePath}>{title}</Link>
      </h3>
    </article>
  )
}

ArticlePreview.propTypes = {
  title: PropTypes.string.isRequired,
  rawDate: PropTypes.string.isRequired,
  prettyDate: PropTypes.string.isRequired,
  pagePath: PropTypes.string.isRequired,
}

export default ArticlePreview
