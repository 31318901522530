import React from "react"
import Layout from "../components/layout"
import Metadata from "../components/metadata"

import styles from "./index.module.css"
import ArticlePreview from "../components/article-preview"
import { graphql, useStaticQuery } from "gatsby"

function IndexPage() {
  const queryResult = useStaticQuery(graphql`
    {
      allContentfulArticle(sort: { fields: [createdAt], order: DESC }) {
        edges {
          node {
            id
            title
            rawDate: createdAt(formatString: "YYYY-MM-DD HH:mm")
            prettyDate: createdAt(formatString: "MMMM DD, YYYY")
            fields {
              pagePath
            }
          }
        }
      }
    }
  `)
  const { allContentfulArticle } = queryResult
  const { edges: articles } = allContentfulArticle
  return (
    <>
      <Metadata />
      <Layout>
        <div className={styles.container}>
          {articles.map(({ node: article }) => {
            const { id, title, rawDate, prettyDate, fields } = article
            const { pagePath } = fields
            return (
              <ArticlePreview
                key={id}
                title={title}
                rawDate={rawDate}
                prettyDate={prettyDate}
                pagePath={pagePath}
              />
            )
          })}
        </div>
      </Layout>
    </>
  )
}

export default IndexPage
